import React, { useState, useEffect, useContext } from "react";
import logo from "../logo.png";
import firebase from "firebase/app";
import { useHistory } from "react-router-dom";
import { Auth } from "../context/auth";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const { user } = useContext(Auth);
  const auth = firebase.auth();
  const history = useHistory();

  const clearInputs = () => {
    setEmail("");
    setPassword("");
  };

  const clearErrors = () => {
    setEmailError("");
    setPassword("");
  };

  useEffect(() => {
    if (user) {
      history.push("/admin");
    }
  }, [history, user]);

  const handleLogin = (e) => {
    e.preventDefault();
    clearErrors();
    auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
      return auth
        .signInWithEmailAndPassword(email, password)
        .then((res) => {
          return true;
        })
        .catch((err) => {
          switch (err.code) {
            case "auth/invalid-email":
            case "auth/user-disabled":
            case "auth/user-not-found":
              setEmailError(err.message);
              break;
            case "auth/wrong-password":
              setPasswordError(err.message);
              break;
          }
        });
    });
    // .signInWithEmailAndPassword(email, password)
    // .catch((err) => {
    //   console.log(err)
    //   switch (err.code) {
    //     case "auth/invalid-email":
    //     case "auth/user-disabled":
    //     case "auth/user-not-found":
    //       setEmailError(err.message);
    //       break;
    //     case "auth/wrong-password":
    //       setPasswordError(err.message);
    //       break;
    //   }
    // });
  };

  const handleLogout = () => {
    firebase.auth().signOut();
  };

  const authListener = () => {};

  // useEffect(() => {
  //   !user ? authListener() : console.log(123);
  // }, [user]);

  return (
    <>
      <div className="bg-gray-light">
        <br />

        <div className="pt-28 pb-28 md:container max-w-xl mx-auto">
          <div className="mt-5 md:mt-0 md:col-span-1">
            <form action="#" method="POST">
              <div className="shadow-lg bg-white-light sm:rounded-md sm:overflow-hidden">
                <div className="flex justify-center mt-10 bg-white-light">
                  <div className="object-center  w-52 h-52">
                    <img className="mx-auto" src={logo} alt="logo" />
                  </div>
                </div>

                <div className="px-4 py-5 bg-white-light space-y-6 sm:p-6">
                  <div>
                    <div className=" flex justify-center">
                      <label
                        htmlFor="company-website"
                        className="block text-4xl font-body font-bold"
                      >
                        Bienvenue
                      </label>
                    </div>
                  </div>

                  <div className="grid grid-cols-1 gap-4 ">
                    <div className="flex justify-center">
                      <div class="mb-4">
                        <label
                          className="block text-gray-700 text-sm font-body mb-2"
                          for="nom"
                        >
                          Nom d'utilisateur
                        </label>
                        <input
                          id="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          type="email"
                          placeholder="nom"
                          className="placeholder-black-dark font-body bg-gray-dark bg-opacity-100 rounded-lg block py-2 px-3 pl-3 pr-40 w-full "
                        />
                        <p className="errorMsg">{emailError}</p>
                      </div>
                    </div>

                    <div className="flex justify-center">
                      <div className="mb-4">
                        <label
                          className="block  text-sm font-body mb-2"
                          for="mot de passe"
                        >
                          le mot de passe
                        </label>
                        <input
                          id="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          type="password"
                          placeholder="mot de passe"
                          className="placeholder-black-dark font-body bg-gray-dark bg-opacity-100 rounded-lg block py-2 px-3 pl-3 pr-40 w-full js-password appearance-none"
                          autocomplete="off"
                        />
                        <p className="errorMsg">{passwordError}</p>
                      </div>
                    </div>

                    <div className="flex justify-center pb-20">
                      <div className="relative  mx-auto pb-10 pr-20">
                        <button
                          onClick={handleLogin}
                          className="bg-green-light  w-24 inset-y-0 right-0 absolute font-body text-white-light font-bold py-2 px-4 rounded"
                        >
                          Confirmer
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <br />
      </div>
    </>
  );
};

export default Login;
