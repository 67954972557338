import React, { useState } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export const CandidatesDetailDialog = (props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button style={{backgroundColor:"#55CEB9", color:"white", fontWeight:"bold", fontFamily:"cabin", fontSize:12}} variant="outlined" onClick={handleClickOpen}>
      Détails
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Referral Details"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>Name: {props.data.ref?.cooptant.firstName}</p>
            <p>Last Name: {props.data.ref?.cooptant.lastName}</p>
            <p>Email: {props.data.ref?.cooptant.email}</p>
            <p>Poste Actuel: {props.data.ref?.cooptant.postalCode}</p>
            <p>Region: {props.data.ref?.cooptant.region}</p>
            <p>Telephone: {props.data.ref?.cooptant.telephone}</p>
            <p>Prime: {props.data.ref?.prime}</p>
            <br />
            <p className="underline">Bank Details</p>
            <p>Name: {props.data.ref?.bankAccount.name}</p>
            <p>Address: {props.data.ref?.bankAccount.address}</p>
            <p>Bic: {props.data.ref?.bankAccount.bic}</p>
            <p>Iban: {props.data.ref?.bankAccount.iban}</p>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Back
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
