import React, { useState, useEffect, useContext } from "react";
import axios from "axios";

import { useHistory } from "react-router-dom";
import firebase from "firebase/app";
import { CandidatesDetailDialog } from "./docs/referalCandidatesDialog";
import { EmailCandidatesCourse } from "./docs/emailCandidatecours";
import { EmailCandidateStatus } from "./docs/emailCandidatestatus";
import { DeleteCandidate } from "./docs/deleteCandidate";

import { FilesDialog } from "./docs/filesDialog";

import TableComponent from "./table";
import { Auth } from "../context/auth";
import { ContactSupportOutlined } from "@material-ui/icons";

const Canditates = () => {
  const db = firebase.firestore();
  const storage = firebase.storage();
  const { user } = useContext(Auth);
  const history = useHistory();
  const [posuler, setPosuler] = useState(null);

  useEffect(() => {
    if (!user) {
      history.replace("/login");
    }
  }, [user, history]);

  const handleLogout = () => {
    firebase.auth().signOut();
  };

  useEffect(() => {
    (async () => {
      db.collection("posuler")
        .orderBy("createdAt", "desc")
        .get()
        .then((snapshot) => {
          const posuler = [];
          snapshot.forEach((doc) => {
            const data = doc.data();
            posuler.push({
              id: doc.id,
              ...data,
            });
          });
          setPosuler(posuler);
        })
        .catch((error) => console.log(error));
    })();
  }, []);

  const columns = [
    {
      title: "Nom",
      field: "name",
      render: (rowData) => `${rowData.lastName}`,
    },
    {
      title: "Email",
      field: "email",
    },
    {
      title: "Téléphone",
      field: "telephone",
    },
    {
      title: "Pièce jointe",
      field: "files",
      render: (rowData) => <FilesDialog data={rowData.files} />,
    },
    {
      title: "Cooptant",
      render: (rowData) => <CandidatesDetailDialog data={rowData} />,
    },
    {
      title: "Offre d'emploi",
      field: "ref.title",
    },
    {
      title: "Entretien en cours",
      render: (rowData) => <EmailCandidatesCourse data={rowData} />,
    },
    {
      title: "statuts",
      field: "status",
    },
    {
      title: "Action",
      render: (rowData) => <EmailCandidateStatus data={rowData} />,
    },
    {
      title: "supprimer le candidat",
      render: (rowData) => <DeleteCandidate data={rowData} />,
    },
  ];

  return (
    <div>
      <br />
      <div className="relative top-0 right-0  mx-auto  pb-10 pr-20">
        <button
          onClick={handleLogout}
          className="bg-green-light  w-30 inset-y-0 right-0 absolute font-body text-white-light font-bold py-2 px-4 rounded"
        >
          Déconnexion
        </button>
      </div>
      <div className="bg-grey-light flex justify-center items-center mb-8">
        <p className="text-2xl font-bold">Postulants</p>
      </div>
      <section className="md:container mx-auto p-6 font-mono">
        <TableComponent columns={columns} data={posuler ? posuler : []} />
      </section>
    </div>
  );
};

export default Canditates;
