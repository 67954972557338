import React, { useState } from "react";
import axios from "axios";
import Button from "@material-ui/core/Button";
import firebase from "firebase/app";

export const EmailCandidatesCourse = (props) => {
  const db = firebase.firestore();
  const [value, setValue] = useState();

  const handleClick = async () => {
    console.log(props);
    try {
      await axios.post(
        "https://api.sendinblue.com/v3/smtp/email",
        {
          sender: {
            name: "Aventurine RH",
            email: "celia.pizzini@aventurine-rh.fr",
          },
          to: [
            {
              email: props.data.ref?.cooptant.email,
              name: "Aventurine Team",
            },
          ],
          subject: "Encore une fois merci pour la cooptation",
          templateId: 4,
          params: {
            firstName: props.data.ref?.cooptant.lastName,
            secondName: props.data.lastName,
          },
        },
        {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
            "api-key":
              "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",
          },
        }
      );
      await db.collection("posuler").doc(props.data.id).update({
        status: "ENTRETIEN EN COURS",
      });
      alert("Email envoyé");
      window.location.reload();
    } catch (error) {
      console.log(error);
      alert("courrier non envoyé");
    }
  };

  return (
    <div className="flex justify-center">
      <Button
        style={{
          backgroundColor: "#55CEB9",
          color: "white",
          fontWeight: "bold",
          fontFamily: "cabin",
          fontSize:12

        }}
        variant="outlined"
        onClick={handleClick}
      >
        Envoyer Mail
      </Button>
    </div>
  );
};
