import React, { useState, useEffect } from "react";
import axios from "axios";
import firebase from "firebase/app";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { ApplianceDialog } from "../components/docs/applianceDialog";

import logo from "../logo.png";

const Appliance = () => {
  const db = firebase.firestore();
  const timestamp = firebase.firestore.FieldValue.serverTimestamp;


  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isRefLoading, setIsRefLoading] = useState(true);
  const [ref, setRef] = useState(null);

  const { watch, register, handleSubmit } = useForm();

  const { search } = useLocation();

  const fetchRef = async () => {
    try {
      const data = (await db.collection("ref").doc(id).get()).data();
      if (!data) throw Error("Error while trying to fetch de ref");
      setRef({ id, ...data });
      setIsRefLoading(false);
    } catch (e) {
      console.log(e);
      setIsRefLoading(false);
    }
  };

  useEffect(() => {
    fetchRef();
  }, []);

  const files = watch("files");

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const storageRef = firebase.storage().ref();

      const fileList = [];
      for await (const file of data.files) {
        const fileName = Date.now() + "-" + file.name;
        const fileRef = storageRef.child(fileName);
        await fileRef.put(file);
        fileList.push({
          fileName: file.name,
          bucketFileName: fileName,
        });
      }
      await db
        .collection("posuler")
        .doc()
        .set({
          ...data,
          createdAt: timestamp(),
          status: "CANDIDATURE EN ETUDE",
          files: fileList,
          ref: {
            ...ref,
          },
        });
      setIsLoading(false);
      alert("Vos informations ont bien été enregistré");
      await axios
        .post(
          "https://api.sendinblue.com/v3/smtp/email",
          {
            sender: {
              name: "Aventurine RH",
              email: "celia.pizzini@aventurine-rh.fr",
            },
            to: [
              {
                email: ref.cooptant.email,
                name: "Aventurine Team",
              },
            ],
            subject: "Nous vous remercions pour la cooptation",
            templateId: 3,
            params: {
              firstName: ref.cooptant.lastName,
              firstName2: data.lastName,
            },
          },
          {
            headers: {
              accept: "application/json",
              "Content-Type": "application/json",
              "api-key":
                "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",
            },
          }
        )
        .catch((e) => console.log(e));

      window.location.reload();
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="bg-gray-light">
        <div className="md:container mx-auto py-5">
          <div className="object-center  w-40 h-40">
            <img className="mx-auto" src={logo} alt="logo" />
          </div>
        </div>

        {!isRefLoading && ref && (
          <div aria-hidden="true">
            <div className="md:container mx-auto">
              <div className="mt-5 md:mt-0 md:col-span-1">
                <form action="#" method="POST">
                  <div className="shadow-lg sm:rounded-md sm:overflow-hidden">
                    <div className="px-4 py-5 bg-white-light space-y-6 sm:p-6">
                      <div>
                        <div className=" flex justify-center">
                          <label
                            htmlFor="company-website"
                            className="block text-2xl text-center font-body font-bold"
                          >
                            Postulez à<div className="pt-2">{ref.title}</div>
                          </label>
                        </div>
                        {/* <div className="flex justify-center">
                        <label
                          htmlFor="company-website"
                          className="block text-base text-center font-body"
                        >
                          Description de l'emploi
                          <div className="pt-2">{ref.description}</div>
                        </label>
                      </div> */}
                      </div>

                      <div className=" text-green-light flex justify-end">
                        <ApplianceDialog data={ref} />
                      </div>

                      <div className="grid grid-cols-1 gap-4">
                        <div>
                          <div className="col-span-2">
                            <label htmlFor="Nom "></label>
                            <input
                              placeholder="Nom "
                              type="text"
                              name="firstName"
                              id="firstName"
                              autoComplete="given-name"
                              className="placeholder-black-dark font-body bg-gray-dark bg-opacity-100 rounded-lg block py-2 px-4 pl-3 w-full"
                              {...register("firstName", {
                                required: true,
                              })}
                            />
                          </div>
                        </div>

                        <div>
                          <div>
                            <label
                              htmlFor="Prénom"
                              className="block text-sm text-gray-700 "
                            ></label>
                            <input
                              placeholder="Prénom"
                              type="text"
                              name="lastName"
                              id="lastName"
                              autoComplete="given-name"
                              className="placeholder-black-dark font-body bg-gray-dark bg-opacity-100 rounded-lg block py-2 px-4 pl-3 w-full"
                              {...register("lastName", {
                                required: true,
                              })}
                            />
                          </div>
                        </div>

                        <div>
                          <div>
                            <label
                              htmlFor="Phone number"
                              className="block text-sm font-medium text-gray-700 "
                            ></label>
                            <input
                              placeholder="Téléphone"
                              type="numeric"
                              name="telephone"
                              id="telephone"
                              autoComplete="given-name"
                              className="placeholder-black-dark font-body bg-gray-dark bg-opacity-100 rounded-lg block py-2 px-4 pl-3 w-full"
                              {...register("telephone", {
                                required: true,
                              })}
                            />
                          </div>
                        </div>

                        <div>
                          <div>
                            <label
                              htmlFor="Email"
                              className="block text-sm font-medium text-gray-700 "
                            ></label>
                            <input
                              placeholder="Email"
                              type="email"
                              name="email"
                              id="email"
                              autoComplete="given-name"
                              className="placeholder-black-dark font-body bg-gray-dark bg-opacity-100 rounded-lg block py-2 px-4 pl-3 w-full"
                              {...register("email", {
                                required: true,
                              })}
                            />
                          </div>
                        </div>

                        <div className="border border-dashed border-gray-500 relative">
                          <input
                            type="file"
                            multiple
                            name="files"
                            className="cursor-pointer relative block opacity-0 w-full h-full p-20 z-50"
                            {...register("files", {
                              required: true,
                            })}
                          />
                          <div className="text-center p-10 absolute top-0 right-0 left-0 m-auto opacity-40">
                            <svg
                              className="mx-auto h-12 w-12 text-gray-400"
                              stroke="currentColor"
                              fill="none"
                              viewBox="0 0 48 48"
                              aria-hidden="true"
                            >
                              <path
                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            <h4>
                              Déposez les fichiers à télécharger
                              <br />
                            </h4>
                            <p className="">pdf, docx, doc, png, jpeg, jpg</p>
                          </div>
                          <div className="absolute left-0 top-0">
                            {files &&
                              Array.from(files).map((file) => (
                                <p>{file.name}</p>
                              ))}
                          </div>
                        </div>
                        <div className="relative justify-center container mx-auto pb-10">
                          <button
                            onClick={handleSubmit(onSubmit)}
                            className={`bg-green-light w-24 inset-y-0 right-0 absolute font-body text-white-light font-bold py-2 px-4 rounded ${
                              isLoading ? "opacity-50 cursor-not-allowed" : ""
                            }`}
                          >
                            Confirmer
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
        <br/>
        <br/>
        <br/>
      </div>
    </>
  );
};

export default Appliance;
