import React from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { CooptantScreen, ApplianceScreen, LoginScreen } from "../screens";
import Referral_page from "../screens/admin/referral-page";
import Admin_page from "../screens/admin/admin-page";
import DashboardLayout from "../dashboard/layout";
import Candidate_page from "../screens/admin/candidate-page";

const Navigation = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <CooptantScreen />
        </Route>
        <Route path="/login">
          <LoginScreen />
        </Route>
        <Route exact path="/posuler/:id">
          <ApplianceScreen />
        </Route>
        <DashboardLayout>
          <Switch>
            <Route exact path="/admin">
              <Admin_page />
            </Route>

            <Route exact path="/admin/coopteur">
              <Referral_page />
            </Route>

            <Route exact path="/admin/candidats">
              <Candidate_page />
            </Route>
          </Switch>
        </DashboardLayout>
      </Switch>
    </Router>
  );
};

export default Navigation;
