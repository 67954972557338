import React from "react";

import App from "./App";
import ReactDOM from "react-dom";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";
import reportWebVitals from "./reportWebVitals";
import {AuthContext} from './context/auth';

import "./index.css";

const firebaseConfig = {
  appId: "1:119384315042:web:983ddab9b7976f520733fb",
  apiKey: "AIzaSyCP5nJfjycNPpvdRxVRxeYGx_Gkf8MkHIM",
  authDomain: "aventurine-coopt.firebaseapp.com",
  storageBucket: "aventurine-coopt.appspot.com",
  messagingSenderId: "119384315042",
  projectId: "aventurine-coopt",
  measurementId: "G-6MGPYC8KZL",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
    <AuthContext>
      <App />
    </AuthContext>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
