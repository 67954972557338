import React, { useState } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import "firebase/auth";
import firebase from "firebase/app";

export const DeleteCandidate = (props) => {
  const db = firebase.firestore();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = async () => {
    console.log(props);
    try {
      await db.collection("posuler").doc(props.data.id).delete();
      window.location.reload();
    } catch (error) {
      console.log(error);
      alert("Candidat non éliminé");
    }
  };

  return (
    <div>
      <Button
        style={{
          backgroundColor: "red",
          color: "white",
          fontWeight: "bold",
          fontSize: 12,
        }}
        variant="outlined"
        onClick={handleClickOpen}
      >
        x
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle style={{fontFamily:"cabin"}}>
          {"Vous souhaitez supprimer un candidat ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className=" grid grid-cols-2 gap-4">
              <div className="flex justify-center">
                <Button
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    fontWeight: "bold",
                    fontFamily:"cabin",
                    fontSize: 12,
                  }}
                  variant="outlined"
                  onClick={handleClick}
                >
                  Supprimer
                </Button>
              </div>

              <div className="flex justify-center">
                <Button style={{fontSize: 12}} onClick={handleClose} variant="outlined">
                  Retour
                </Button>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};
