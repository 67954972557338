import React, { useState, useEffect } from "react";
import firebase from "firebase/app";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export const FilesDialog = ({ data }) => {
  const storage = firebase.storage();

  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    fetchUrls(data);
  }, []);

  const fetchUrls = async (data) => {
    try {
      const fileList = [];
      for await (const file of data) {
        const storageRef = storage.ref(file.bucketFileName);
        const url = await storageRef.getDownloadURL();
        fileList.push({
          url,
          fileName: file.fileName,
        });
      }
      setFiles(fileList);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Button
        style={{
          backgroundColor: "#55CEB9",
          color: "white",
          fontWeight: "bold",
          fontFamily: "cabin",
          fontSize:12

        }}
        variant="outlined"
        onClick={handleClickOpen}
      >
        Docs
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">{"Files"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {files.map((file) => (
              <p>
                <a
                  className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                  href={file.url}
                  target="_blank"
                >
                  {file.fileName}
                </a>
              </p>
            ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Back
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
