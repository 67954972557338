import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import firebase from "firebase/app";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import logo from "../logo.png";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
} from "react-share";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ErrorMessage } from "@hookform/error-message";
import { Acordion } from "../components/acordion";

const Cooptant = ({ history }) => {
  const db = firebase.firestore();

  const schema = yup.object().shape({
    iban: yup
      .string()
      .required()
      .matches(
        /^(?:(?:IT|SM)\d{2}[A-Z]\d{22}|CY\d{2}[A-Z]\d{23}|NL\d{2}[A-Z]{4}\d{10}|LV\d{2}[A-Z]{4}\d{13}|(?:BG|BH|GB|IE)\d{2}[A-Z]{4}\d{14}|GI\d{2}[A-Z]{4}\d{15}|RO\d{2}[A-Z]{4}\d{16}|KW\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{23}|NO\d{13}|(?:DK|FI|GL|FO)\d{16}|MK\d{17}|(?:AT|EE|KZ|LU|XK)\d{18}|(?:BA|HR|LI|CH|CR)\d{19}|(?:GE|DE|LT|ME|RS)\d{20}|IL\d{21}|(?:AD|CZ|ES|MD|SA)\d{22}|PT\d{23}|(?:BE|IS)\d{24}|(?:FR|MR|MC)\d{25}|(?:AL|DO|LB|PL)\d{26}|(?:AZ|HU)\d{27}|(?:GR|MU)\d{28})$/i,
        "Le format de l'IBAN n'est pas correct"
      ),
    email: yup
      .string()
      .email("Le format de l'email est invalide")
      .required("Requis"),

    telephone: yup
      .string()
      .required("Requis")
      .min(10, "10 chiffres requis")
      .max(10, "10 chiffres requis"),
  });

  const [formStep, setFormStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState(null);
  const timestamp = firebase.firestore.FieldValue.serverTimestamp;

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const firstName = watch("firstName");
  const lastName = watch("lastName");
  const postalCode = watch("postalCode");
  const region = watch("region");
  const email = watch("email");
  const telephone = watch("telephone");
  const notifications = watch("notifications");
  const bankAccountName = watch("bankAccountName");
  const bankAccountAddress = watch("bankAccountAddress");
  const iban = watch("iban");
  const bic = watch("bic");
  const firstName2 = watch("firstName2");
  const lastName2 = watch("lastName2");
  const email2 = watch("email2");
  const linkedIn = watch("linkedIn");
  const telephone2 = watch("telephone2");
  const conditions = watch("conditions");

  const { search } = useLocation();
  const { prime, url, title } = queryString.parse(search);

  useEffect(() => {
    if (
      firstName &&
      lastName &&
      postalCode &&
      region &&
      email &&
      telephone &&
      notifications !== undefined
    ) {
      setFormStep(1);
    } else {
      setFormStep(0);
    }
  }, [
    firstName,
    lastName,
    postalCode,
    region,
    email,
    telephone,
    notifications,
  ]);

  useEffect(() => {
    if (
      bankAccountName &&
      bankAccountAddress &&
      iban &&
      bic &&
      conditions == "true"
    ) {
      setFormStep(2);
    } else if (formStep === 2) {
      setFormStep(1);
    }
  }, [bankAccountName, bankAccountAddress, iban, bic, conditions]);

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const doc = db.collection("ref").doc();
      const cooptant = {
        title,
        prime,
        url,
        // description,
        cooptant: {
          firstName: data.firstName,
          lastName: data.lastName,
          postalCode: data.postalCode,
          region: data.region,
          email: data.email,
          telephone: data.telephone,
          notifications: data.notifications,
        },
        bankAccount: {
          name: data.bankAccountName,
          address: data.bankAccountAddress,
          iban: data.iban,
          bic: data.bic,
        },
      };
      await doc.set(cooptant);
      setIsLoading(false);
      setId(doc.id);
      setFormStep(3);
      await axios
        .post(
          "https://api.sendinblue.com/v3/smtp/email",
          {
            sender: {
              name: "Aventurine RH",
              email: "celia.pizzini@aventurine-rh.fr",
            },
            to: [
              {
                email,
                name: "Aventurine Team",
              },
            ],
            subject:
              "Ça y est vous faite partie de la communauté des coopteurs !",
            templateId: 2,
            params: {
              firstName: lastName,
            },
          },
          {
            headers: {
              accept: "application/json",
              "Content-Type": "application/json",
              "api-key":
                "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",
            },
          }
        )
        .catch((e) => console.log(e));
      alert(
        "Votre inscription est terminée, vous recevrez un mail de confirmation dans votre boîte mail"
      );
    } catch (error) {
      console.log(error);
      alert("Erreur! Essayer à nouveau!");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (firstName2 && lastName2 && email2 && telephone2) {
      setFormStep(4);
    } else if (formStep === 4) {
      setFormStep(3);
    }
  }, [firstName2, lastName2, email2, telephone2]);

  const registerApplicant = async (data) => {
    try {
      setIsLoading(true);
      await db
        .collection("posuler")
        .doc()
        .set({
          createdAt: timestamp(),
          firstName: data.firstName2,
          lastName: data.lastName2,
          email: data.email2,
          linkedIn: data.linkedIn || null,
          telephone: data.telephone2,
          status: "CANDIDATURE EN ETUDE",
          ref: {
            prime,
            title,
            cooptant: {
              firstName: data.firstName,
              lastName: data.lastName,
              postalCode: data.postalCode,
              region: data.region,
              email: data.email,
              telephone: data.telephone,
              notifications: data.notifications,
            },
            bankAccount: {
              name: data.bankAccountName,
              address: data.bankAccountAddress,
              iban: data.iban,
              bic: data.bic,
            },
          },
        });
      await axios
        .post(
          "https://api.sendinblue.com/v3/smtp/email",
          {
            sender: {
              name: "Aventurine RH",
              email: "celia.pizzini@aventurine-rh.fr",
            },
            to: [
              {
                email,
                name: "Aventurine Team",
              },
            ],
            subject:
              "Ça y est vous faite partie de la communauté des coopteurs !",
            templateId: 2,
            params: {
              firstName: lastName,
            },
          },
          {
            headers: {
              accept: "application/json",
              "Content-Type": "application/json",
              "api-key":
                "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",
            },
          }
        )
        .catch((e) => console.log(e));
      setIsLoading(false);
      alert("Vos informations ont bien été enregistré");
    } catch (error) {
      alert("Erreur! Essayer à nouveau!");
      setIsLoading(false);
    }
  };

  const copyToClipboard = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(
      `https://aventurine-coopt.web.app/posuler/${id}`
    );
  };

  return (
    <>
      <div className="bg-gray-light">
        <div className=" md:container mx-auto py-5 px-20">
          <div className="object-center  w-40 h-40">
            <img className="mx-auto" src={logo} alt="logo" />
          </div>
        </div>
        <br />

        <div className="flex justify-center">
          <div className="pr-24 hidden md:block"></div>

          <div className="w-auto">
            <Acordion />
          </div>

          <div className="pl-20 hidden md:block"></div>
        </div>
        <br />

        <div className="flex flex-row justify-center">
          <div className="hidden md:block">
            <div className="px-8">
              <div className=" font-body text-white-light font-bold relative w-6 h-6 bg-green-light rounded flex justify-center items-center text-center p-5 shadow-xl">
                1
              </div>
            </div>

            {formStep >= 1 && (
              <div>
                <div className="px-12">
                  <div className="border-solid border-4 h-96 w-3 bg-green-light border-gray-light "></div>
                </div>

                <div className="px-8">
                  <div className=" font-body text-white-light font-bold relative w-6 h-6 bg-green-light rounded flex justify-center items-center text-center p-5 shadow-xl">
                    2
                  </div>
                </div>
              </div>
            )}

            {formStep >= 3 && (
              <div>
                <div className="px-12">
                  <div className="border-solid border-4 h-96 w-3 bg-green-light border-gray-light "></div>
                </div>

                <div className="px-8">
                  <div className=" font-body text-white-light font-bold relative w-6 h-6 bg-green-light rounded flex justify-center items-center text-center p-5 shadow-xl">
                    3
                  </div>
                </div>
              </div>
            )}
          </div>

          <div>
            <div className="md:container mx-auto">
              <div className="mt-5 md:mt-0 md:col-span-2">
                <form action="#" method="POST">
                  <div className="shadow-lg sm:rounded-md sm:overflow-hidden">
                    <div className="px-4 py-5 bg-white-light space-y-6 sm:p-6">
                      <div className="grid grid-cols-3 gap-6">
                        <div className="col-span-3 sm:col-span-2">
                          <label
                            htmlFor="company-website"
                            className="block text-2xl font-body font-bold"
                          >
                            Coopteur
                          </label>
                        </div>
                      </div>

                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                          <div className="col-span-2">
                            <label htmlFor="Nom "></label>
                            <input
                              placeholder="Nom "
                              type="text"
                              name="firstName"
                              id="firstName"
                              autoComplete="given-name"
                              className="placeholder-black-dark font-body bg-gray-dark bg-opacity-100 rounded-lg block py-2 px-4 pl-3 w-full"
                              {...register("firstName", {
                                required: true,
                              })}
                            />
                          </div>
                        </div>

                        <div>
                          <div className="col-span-2">
                            <label
                              htmlFor="Prénom"
                              className="block text-sm text-gray-700 "
                            ></label>
                            <input
                              placeholder="Prénom"
                              type="text"
                              name="lastName"
                              id="lastName"
                              autoComplete="given-name"
                              className="placeholder-black-dark font-body bg-gray-dark bg-opacity-100 rounded-lg block py-2 px-4 pl-3 w-full"
                              {...register("lastName", {
                                required: true,
                              })}
                            />
                          </div>
                        </div>

                        <div>
                          <div className="col-start-1 col-span-2 ">
                            <label
                              htmlFor="Poste actuel"
                              className="block text-sm font-medium text-gray-700 "
                            ></label>
                            <input
                              placeholder="Emploi"
                              type="text"
                              name="postalCode"
                              id="postalCode"
                              autoComplete="given-name"
                              className="placeholder-black-dark font-body bg-gray-dark bg-opacity-100 rounded-lg block py-2 px-4 pl-3 w-full"
                              {...register("postalCode", {
                                required: true,
                              })}
                            />
                          </div>
                        </div>

                        <div>
                          <div className="col-start-1 col-span-2 ">
                            <div>
                              <label
                                htmlFor="Ta région"
                                className="block text-sm font-medium text-gray-700 "
                              ></label>

                              <select
                                id="region"
                                name="region"
                                placeholder="Ta région"
                                className="placeholder-black-dark font-body bg-gray-dark bg-opacity-100 rounded-lg block py-2 px-4 pl-3 w-full"
                                {...register("region", {
                                  required: true,
                                })}
                              >
                                <option>Ta région</option>
                                <option>Guadeloupe</option>
                                <option>Martinique</option>
                                <option>La Réunion</option>
                                <option>Mayotte</option>
                                <option>Auvergne-Rhône-Alpes</option>
                                <option>Bourgogne-Franche-Comté</option>
                                <option>Bretagne</option>
                                <option>Centre-Val de Loire</option>
                                <option>Corse</option>
                                <option>Grand Est</option>
                                <option>Hauts-de-France</option>
                                <option>Île-de-France</option>
                                <option>Normandie</option>
                                <option>Nouvelle-Aquitaine</option>
                                <option>Occitanie</option>
                                <option>Pays de la Loire</option>
                                <option>Provence-Alpes-Côte d'Azur</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div>
                          <div className="col-start-1 col-span-2 ">
                            <label
                              htmlFor="Email"
                              className="block text-sm font-medium text-gray-700 "
                            ></label>
                            <input
                              placeholder="Email"
                              type="email"
                              name="email"
                              id="email"
                              autoComplete="given-name"
                              className="placeholder-black-dark font-body bg-gray-dark bg-opacity-100 rounded-lg block py-2 px-4 pl-3 w-full"
                              {...register("email", {
                                required: true,
                              })}
                            />
                            <ErrorMessage errors={errors} name="email" />

                            <ErrorMessage
                              errors={errors}
                              name="email"
                              render={({ message }) => (
                                <p>
                                  Example de format d'email: example@domain.com
                                </p>
                              )}
                            />
                          </div>
                        </div>

                        <div>
                          <div className="col-start-1 col-span-2 ">
                            <label
                              htmlFor="Téléphone"
                              className="block text-sm font-medium text-gray-700 "
                            ></label>
                            <input
                              placeholder="Téléphone"
                              type="number"
                              name="telephone"
                              id="telephone"
                              autoComplete="phone"
                              className="placeholder-black-dark font-body bg-gray-dark bg-opacity-100 rounded-lg block py-2 px-4 pl-3 w-full"
                              {...register("telephone", {
                                required: true,
                              })}
                            />
                            <ErrorMessage errors={errors} name="telephone" />

                            <ErrorMessage
                              errors={errors}
                              name="telephone"
                              render={({ message }) => (
                                <p>
                                  Exemple de format de numéro de téléphone:
                                  0696788525
                                </p>
                              )}
                            />
                          </div>
                        </div>

                        <p className="text-base font-body">
                          Je souhaite recevoir par email la notification de
                          nouveaux poste proposés par Aventurine RH.
                        </p>
                      </div>

                      <div className="mt-4">
                        <div className="mt-2">
                          <label className="hover:bg-green-dark text-teal-dark hover:text-white py-2 px-4 border-4 border-gray-dark rounded-lg mr-2">
                            <input
                              type="radio"
                              className="form-radio"
                              name="notifications"
                              value={true}
                              {...register("notifications", {
                                required: true,
                              })}
                            />
                            <span className="ml-2 font-body">Oui</span>
                          </label>
                          <label className="hover:bg-green-dark text-teal-dark hover:text-white py-2 px-4 border-4 border-gray-dark rounded-lg mr-2">
                            <input
                              type="radio"
                              className="form-radio "
                              name="notifications"
                              value={false}
                              {...register("notifications", {
                                required: true,
                              })}
                            />
                            <span className="ml-2 font-body">Non</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <br />

            <div className="mt-10 sm:mt-0 bg-gray-light">
              <div className="md:container mx-auto">
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <form action="#" method="POST">
                    <div className="shadow-lg sm:rounded-md sm:overflow-hidden">
                      <div className="px-4 py-5 bg-white-light space-y-6 sm:p-6">
                        <div className="grid grid-cols-3 gap-6">
                          <div className="col-span-3 sm:col-span-2">
                            <label
                              htmlFor="company-website"
                              className="block font-body  text-2xl font-bold"
                            >
                              Coordonnées bancaires
                            </label>
                          </div>
                        </div>

                        <p className="text-base font-body">
                          Si la personne que vous cooptez fait l’objet d’un
                          recrutement, vous serez averti par mail et vous
                          recevrez votre prime, directement par virement
                          bancaire à ce compte.
                        </p>

                        <div className="grid grid-cols-2 gap-4">
                          <div className="col-span-2">
                            <div>
                              <label
                                htmlFor="Nom complet"
                                className="block text-sm font-medium text-gray-700"
                              ></label>
                              <input
                                placeholder="Nom complet"
                                type="text"
                                name="bankAccountName"
                                id="bankAccountName"
                                autoComplete="given-name"
                                className="placeholder-black-dark font-body bg-gray-dark bg-opacity-100 rounded-lg block py-2 px-4 pl-3 w-full "
                                {...register("bankAccountName", {
                                  required: true,
                                })}
                              />
                            </div>
                          </div>

                          <div className="col-span-2">
                            <div className="col-span-6">
                              <label
                                htmlFor="Adresse"
                                className="block text-sm font-medium text-gray-700 "
                              ></label>
                              <input
                                placeholder="Adresse"
                                type="text"
                                name="bankAccountAddress"
                                id="bankAccountAddress"
                                autoComplete="given-name"
                                className="placeholder-black-dark font-body bg-gray-dark bg-opacity-100 rounded-lg block py-2 px-4 pl-3 w-full"
                                {...register("bankAccountAddress", {
                                  required: true,
                                })}
                              />
                            </div>
                          </div>

                          <div>
                            <div className="col-span-6 sm:col-span-3 ">
                              <label
                                htmlFor="IBAN"
                                className="block text-sm font-medium text-gray-700 "
                              ></label>

                              <input
                                placeholder="IBAN"
                                type="text"
                                name="iban"
                                id="iban"
                                autoComplete="given-name"
                                className="placeholder-black-dark font-body bg-gray-dark bg-opacity-100 rounded-lg block py-2 px-4 pl-3 w-full"
                                {...register("iban", {
                                  required: true,
                                })}
                              />
                              <ErrorMessage errors={errors} name="iban" />

                              <ErrorMessage
                                errors={errors}
                                name="iban"
                                render={({ message }) => (
                                  <p>
                                    Example de format d'IBAN
                                    FR7630001007941234567890185
                                  </p>
                                )}
                              />
                            </div>
                          </div>

                          <div>
                            <div className="col-span-6 sm:col-span-3 ">
                              <label
                                htmlFor="BIC /SWIFT"
                                className="block text-sm font-medium text-gray-700 "
                              ></label>
                              <input
                                placeholder="BIC /SWIFT"
                                type="text"
                                name="bic"
                                id="bic"
                                autoComplete="given-name"
                                className="placeholder-black-dark font-body bg-gray-dark bg-opacity-100 rounded-lg block py-2 px-4 pl-3 w-full"
                                {...register("bic", {
                                  required: true,
                                })}
                              />
                            </div>
                          </div>

                          <div className="col-span-2">
                            <p className="text-base font-body">
                              J’accepte les
                              <a
                                href="https://drive.google.com/file/d/1mcXAODHsHFvoTPxQZWxji6eoETfJ1HLe/view?usp=sharing"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-green-light underline"
                              >
                                {` conditions générales d’utilisations et la
                              politique de confidentialité `}
                              </a>{" "}
                              d’Aventurine RH.
                            </p>
                          </div>
                        </div>
                        <div className="mt-4">
                          <div className="mt-2">
                            <label className=" hover:bg-green-dark text-teal-dark hover:text-white py-2 px-4 border-4 border-gray-dark rounded-lg mr-2">
                              <input
                                type="radio"
                                className="form-radio"
                                name="conditions3"
                                value={true}
                                {...register("conditions")}
                              />
                              <span className="ml-2 font-body">Oui</span>
                            </label>
                            <label className="hover:bg-green-dark text-teal-dark hover:text-white py-2 px-4 border-4 border-gray-dark rounded-lg mr-2">
                              <input
                                type="radio"
                                className="form-radio "
                                name="conditions3"
                                value={false}
                                {...register("conditions")}
                              />
                              <span className="ml-2 font-body">Non</span>
                            </label>
                          </div>
                        </div>
                        <div className="relative md:container mx-auto pb-10 mb-20">
                          <button
                            onClick={handleSubmit(onSubmit)}
                            disabled={isLoading || formStep !== 2}
                            className={`bg-green-light justify-items-center justify-center  items-center flex-row w-24 inset-y-0 right-0  absolute font-body text-white-light font-bold py-2 px-4 rounded ${
                              isLoading || formStep != 2 ? "opacity-50" : ""
                            }`}
                          >
                            Confirmer
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <br />

            <div className="mt-10 sm:mt-0 bg-opacity-100">
              <div
                className={`md:container mx-auto ${
                  formStep < 3 ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <form action="#" method="POST">
                    <div className="shadow-lg sm:rounded-md sm:overflow-hidden">
                      <div className="px-4 py-5 bg-white-light space-y-6 sm:p-6">
                        <div className="grid grid-cols-3 gap-6">
                          <div className="col-span-3 sm:col-span-2">
                            <label
                              htmlFor="company-website"
                              className="block font-body text-2xl font-bold"
                            >
                              Coopté
                            </label>
                          </div>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          <div>
                            <div className="col-span-9 ">
                              <label
                                htmlFor="Nom"
                                className="block text-sm font-medium text-gray-700 "
                              ></label>
                              <input
                                placeholder="Nom"
                                type="text"
                                disabled={formStep < 3}
                                name="firstName2"
                                id="firstName2"
                                autoComplete="given-name"
                                className="placeholder-black-dark font-body bg-gray-dark bg-opacity-100 rounded-lg block py-2 px-4 pl-3 w-full "
                                {...register("firstName2", {
                                  required: true,
                                })}
                              />
                            </div>
                          </div>

                          <div>
                            <div className="col-span-6">
                              <label
                                htmlFor="Prénom"
                                className="block text-sm font-medium text-gray-700 "
                              ></label>
                              <input
                                placeholder="Prénom"
                                type="text"
                                disabled={formStep < 3}
                                name="lastName2"
                                id="lastName2"
                                autoComplete="given-name"
                                className="placeholder-black-dark font-body bg-gray-dark bg-opacity-100 rounded-lg block py-2 px-4 pl-3 w-full"
                                {...register("lastName2", {
                                  required: true,
                                })}
                              />
                            </div>
                          </div>

                          <div>
                            <div className="col-span-6 sm:col-span-3 ">
                              <label
                                htmlFor="Email"
                                className="block text-sm font-medium text-gray-700 "
                              ></label>
                              <input
                                placeholder="Email"
                                type="email"
                                disabled={formStep < 3}
                                name="email2"
                                id="email2"
                                autoComplete="email"
                                className="placeholder-black-dark font-body bg-gray-dark bg-opacity-100 rounded-lg block py-2 px-4 pl-3 w-full"
                                {...register("email2", {
                                  required: true,
                                })}
                              />
                            </div>
                          </div>

                          <div>
                            <div className="col-span-6 sm:col-span-3 ">
                              <label
                                htmlFor="Linkedin"
                                className="block text-sm font-medium text-gray-700 "
                              ></label>
                              <input
                                placeholder="LinkedIn"
                                type="text"
                                disabled={formStep < 3}
                                name="linkedin"
                                id="linkedin"
                                className="placeholder-black-dark font-body bg-gray-dark bg-opacity-100 rounded-lg block py-2 px-4 pl-3 w-full"
                                {...register("linkedIn", {
                                  required: false,
                                })}
                              />
                            </div>
                          </div>

                          <div>
                            <div className="col-span-6 sm:col-span-3 ">
                              <label
                                htmlFor="Téléphone"
                                className="block text-sm font-medium text-gray-700 "
                              ></label>
                              <input
                                placeholder="Téléphone"
                                type="text"
                                disabled={formStep < 3}
                                name="telephone2"
                                id="telephone2"
                                className="placeholder-black-dark font-body bg-gray-dark bg-opacity-100 rounded-lg block py-2 px-4 pl-3 w-full"
                                {...register("telephone2", {
                                  required: true,
                                })}
                              />
                            </div>
                          </div>

                          <div></div>

                          <p className="text-base font-body"></p>

                          <div className="relative md:container mx-auto pb-10 mb-20">
                            <button
                              onClick={handleSubmit(registerApplicant)}
                              disabled={formStep !== 4}
                              className={`bg-green-light justify-items-center justify-center  items-center flex-row w-24 inset-y-0 right-0  absolute font-body text-white-light font-bold py-2 px-4 rounded ${
                                isLoading || formStep !== 4 || !conditions
                                  ? "opacity-50 cursor-not-allowed"
                                  : ""
                              }`}
                            >
                              Confirmer
                            </button>
                          </div>

                          <div>
                            <p className="text-base font-body">
                              Les boutons ci-dessous créent un lien qui
                              t’identifie et nous permet de savoir que c’est à
                              toi qu’il faut verser la prime si ton candidat est
                              engagé.
                            </p>
                          </div>

                          <div></div>

                          <div className="flex justify-end">
                            <textarea
                              value={
                                id
                                  ? `https://aventurine-coopt.web.app/posuler/${id}`
                                  : ""
                              }
                              type="text"
                              disabled={true}
                              name="refLink"
                              id="refLink"
                              className="placeholder-black-dark font-body bg-gray-dark bg-opacity-100 rounded-l-lg block py-2 pl-3 w-full"
                            />

                            <button
                              onClick={copyToClipboard}
                              className="bg-green-light font-body text-white-light font-bold py-2 px-10 rounded-r-lg"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeWidth="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                                />
                              </svg>
                            </button>
                          </div>

                          <div className="grid md:grid-flow-col grid-cols-2  gap-4">
                            <div className="font-bold font-body text-2xl">
                              <p>Prime à gagner</p>
                            </div>

                            <button className="bg-green-light font-body text-white-light font-bold py-2 px-4 rounded cursor-not-allowed">
                              {prime}€
                            </button>
                          </div>

                          <p>
                            N’hésite pas à partager ce lien aux personnes
                            correspondant à l’offre d’emploi
                          </p>

                          <div></div>

                          <div className="grid grid-cols-10 gap-4">
                            <LinkedinShareButton
                              url={
                                id
                                  ? `https://aventurine-coopt.web.app/posuler/${id}`
                                  : ""
                              }
                              title={`Postulez à ${title}`}
                              className="share"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="29.999"
                              >
                                <path d="M6.715 29.999H.5V9.97h6.22zM3.6 7.238a3.619 3.619 0 113.6-3.639 3.632 3.632 0 01-3.6 3.639zm26.393 22.761h-6.206v-9.75c0-2.324-.047-5.3-3.234-5.3-3.234 0-3.729 2.525-3.729 5.136v9.914h-6.213V9.97h5.965v2.729h.087a6.535 6.535 0 015.885-3.234C28.843 9.468 30 13.613 30 18.999v11z" />
                              </svg>
                            </LinkedinShareButton>

                            <WhatsappShareButton
                              url={
                                id
                                  ? `https://aventurine-coopt.web.app/posuler/${id}`
                                  : ""
                              }
                              title="Postulez à "
                              separator={title}
                              className="share"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="30"
                              >
                                <path d="M15.272 0A14.671 14.671 0 00.545 14.614a14.463 14.463 0 002.113 7.546L0 30l8.15-2.59a14.752 14.752 0 0021.846-12.8A14.671 14.671 0 0015.272 0zm7.323 20.164a3.8 3.8 0 01-2.6 1.679c-.691.037-.71.535-4.476-1.1s-6.03-5.615-6.209-5.871a7.222 7.222 0 01-1.389-3.914A4.173 4.173 0 019.35 7.895a1.442 1.442 0 011.02-.429c.3 0 .489-.009.708 0s.549-.046.834.712.967 2.622 1.054 2.812a.682.682 0 01.007.654 2.558 2.558 0 01-.4.608c-.2.211-.413.472-.589.633-.2.178-.4.373-.194.755a11.27 11.27 0 001.994 2.661 10.276 10.276 0 002.953 1.963c.369.2.59.179.819-.062s.98-1.054 1.245-1.418.513-.293.853-.156 2.153 1.109 2.523 1.309.616.3.7.462a3.093 3.093 0 01-.282 1.765z" />
                              </svg>
                            </WhatsappShareButton>

                            <FacebookShareButton
                              url={
                                id
                                  ? `https://aventurine-coopt.web.app/posuler/${id}`
                                  : ""
                              }
                              quote={`Postulez à ${title}`}
                              hashtag="#AVENTURINE RH"
                              className="share"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16.068"
                                height="30"
                              >
                                <path d="M15.015 16.875l.833-5.429h-5.21V7.922a2.715 2.715 0 013.061-2.933h2.368V.367A28.882 28.882 0 0011.864 0c-4.29 0-7.095 2.6-7.095 7.308v4.138H0v5.429h4.769V30h5.869V16.875z" />
                              </svg>
                            </FacebookShareButton>

                            <TelegramShareButton
                              url={
                                id
                                  ? `https://aventurine-coopt.web.app/posuler/${id}`
                                  : ""
                              }
                              title={`Postulez à ${title}`}
                              className="share"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="25.159"
                              >
                                <path d="M29.915 2.291l-4.527 21.35c-.342 1.507-1.232 1.882-2.5 1.172l-6.9-5.083-3.328 3.2a1.732 1.732 0 01-1.386.676l.5-7.025L24.557 5.03c.556-.5-.121-.77-.864-.275l-15.8 9.952-6.8-2.13c-1.48-.462-1.507-1.48.308-2.19L28.006.134c1.232-.462 2.31.275 1.909 2.156z" />
                              </svg>
                            </TelegramShareButton>

                            <TwitterShareButton
                              url={
                                id
                                  ? `https://aventurine-coopt.web.app/posuler/${id}`
                                  : ""
                              }
                              title={`Postulez à ${title}`}
                              className="share"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="24.366"
                              >
                                <path d="M26.916 6.072c.019.266.019.533.019.8 0 8.128-6.187 17.494-17.494 17.494A17.375 17.375 0 010 21.605a12.719 12.719 0 001.485.076 12.314 12.314 0 007.633-2.627 6.159 6.159 0 01-5.749-4.264 7.754 7.754 0 001.161.1 6.5 6.5 0 001.618-.209 6.149 6.149 0 01-4.93-6.034v-.076A6.192 6.192 0 004 9.346a6.158 6.158 0 01-1.906-8.227 17.477 17.477 0 0012.678 6.434 6.941 6.941 0 01-.152-1.409 6.154 6.154 0 0110.64-4.203 12.105 12.105 0 003.9-1.485 6.132 6.132 0 01-2.7 3.388A12.326 12.326 0 0030 2.893a13.217 13.217 0 01-3.084 3.179z" />
                              </svg>
                            </TwitterShareButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cooptant;
