import React, { useState } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import "firebase/auth";
import firebase from "firebase/app";

export const AdminDialog = () => {
  const auth = firebase.auth();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const registrarUsuarios = (e) => {
    e.preventDefault();
    auth
      .createUserWithEmailAndPassword(email, password)
      .then((res) => window.location.reload());
  };

  return (
    <div>
      <Button style={{backgroundColor:"#55CEB9", color:"white", fontWeight:"bold", fontFamily:"cabin"}} variant="outlined" onClick={handleClickOpen}>
        Créer un nouvel admin
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Créer un nouvel admin"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="grid grid-cols-1 md:grid-cols-1">
              <form onSubmit={registrarUsuarios} className="form-group mt-3">
                <input
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  className="form-control placeholder-black-dark font-body bg-gray-dark bg-opacity-100 rounded-lg block py-2 px-4 pl-3 w-full"
                  type="email"
                  placeholder="Email"
                />
                <br />
                <input
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  className="form-control placeholder-black-dark font-body bg-gray-dark bg-opacity-100 rounded-lg block py-2 px-4 pl-3 w-full"
                  type="password"
                  placeholder="Password"
                />
                <br />
                <div className="  relative  mx-auto  pb-10 pr-20">
                  <button
                    type="submit"
                    className="bg-green-light absolute inset-0 font-body text-white-light font-bold py-2 px-4 rounded"
                    value="register"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Back
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
