import React, { useState, useEffect, useContext } from "react";
import firebase from "firebase/app";
import TableComponent from "./table";

import { ReferalDetailDialog } from "./docs/referalDetailDialog";
import { useHistory } from "react-router-dom";
import { Auth } from "../context/auth";

const Referral = () => {
  const history = useHistory();
  const { user } = useContext(Auth);

  const db = firebase.firestore();

  const [ref, setRef] = useState(null);

  useEffect(() => {
    if (!user) {
      history.replace("/login");
    }
  }, [history, user]);

  const handleLogout = () => {
    firebase.auth().signOut();
  };

  useEffect(() => {
    (async () => {
      db.collection("ref")
        .get()
        .then((snapshot) => {
          const ref = [];
          snapshot.forEach((doc) => {
            const data = doc.data();
            ref.push(data);
          });
          setRef(ref);
        })
        .catch((error) => console.log(error));
    })();
  }, []);

  const columns = [
    {
      title: "Nom Prénom",
      field: "name",
      render: (rowData) =>
        `${rowData.cooptant.firstName} ${rowData.cooptant.lastName}`,
    },
    {
      title: "Email",
      field: "cooptant.email",
    },
    {
      title: "Poste",
      field: "cooptant.postalCode",
    },
    {
      title: "Région",
      field: "cooptant.region",
    },
    {
      title: "Téléphone",
      field: "cooptant.telephone",
    },
    {
      title: "Prime",
      field: "prime",
    },
    {
      title: "RIB",
      field: "bankAccount",
      render: (rowData) => <ReferalDetailDialog data={rowData} />,
    },
    {
      title: "Emploi",
      field: "title",
    },
    {
      title: "Lien de l'offre d'emploi",
      field: "link",
      render: (rowData) => (
        <a target="_blank" href={rowData.url}>
          {rowData.url}
        </a>
      ),
    },
  ];

  return (
    <div>
      <br/>
      <div className="relative top-0 right-0  mx-auto pb-10 pr-20">
        <button
          onClick={handleLogout}
          className="bg-green-light  w-30 inset-y-0 right-0 absolute font-body text-white-light font-bold py-2 px-4 rounded"
        >
          Déconnexion
        </button>
      </div>
      <div className="bg-grey-light flex justify-center items-center mb-8">
        <p className="text-2xl font-bold">Cooptation</p>
      </div>
      <section className="md:container mx-auto p-6 font-mono">
        <TableComponent columns={columns} data={ref ? ref : []} />
      </section>
    </div>
  );
};

export default Referral;
