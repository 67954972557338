import {useContext} from 'react'
import {Auth} from '../context/auth'
import axios from "axios";
import firebase from "firebase/app";
import "firebase/auth";


const api = axios.create({
  baseURL: `https://us-central1-aventurine-coopt.cloudfunctions.net/app`,
  headers: {
    "Content-Type": "application/json",
  },
});

export default api;
