import React, { useState, useEffect, useContext } from "react";
import firebase from "firebase/app";
import { useHistory } from "react-router-dom";
import api from "../helpers/api";
import TableComponent from "./table";
import { Auth } from "../context/auth";
import { AdminDialog } from "./docs/adminDialog";

const Admin = () => {
  const { user } = useContext(Auth);
  const history = useHistory();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (!user) {
      history.push("/login");
    }
  }, [history, user]);

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleLogout = () => {
    firebase.auth().signOut();
  };

  const fetchUsers = async () => {
    try {
      const token = await user.getIdToken();
      const response = await api.get("/admin", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUsers(response.data.data);
    } catch (error) {
      alert("Error while trying to fetch the admins");
    }
  };

  const columns = [
    {
      title: "Email",
      field: "email",
    },
    {
      title: "Crée le",
      field: "metadata.creationTime",
    },
    {
      title: "Statuts",
      field: "disabled",
    },
  ];

 

  return (
    <div>
      <br />
      <div className="relative top-0 right-0  mx-auto pb-10 pr-20">
        <button
          onClick={handleLogout}
          className="bg-green-light  w-30 inset-y-0 right-0 absolute font-body text-white-light font-bold py-2 px-4 rounded"
        >
          Déconnexion
        </button>

      </div>
      <div className="bg-grey-light flex justify-center items-center mb-8">
        <p className="text-2xl font-bold">Admin</p>
      </div>
      <section className="md:container mx-auto p-6 font-mono">
        <TableComponent columns={columns} data={users} />
      </section>
      <div id="adminDialog">
        <AdminDialog/>
      </div>
    </div>
  );
};

export default Admin;
