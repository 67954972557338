import React, { useEffect, useState, useRef } from "react";
import lottie from "lottie-web";
import { ExpandMore } from "@material-ui/icons";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";

export const Acordion = () => {
  const container = useRef(null);
  const container2 = useRef(null);
  const container3 = useRef(null);
  const container4 = useRef(null);
  const container5 = useRef(null);
  const container6 = useRef(null);
  const container7 = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      render: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../coopteur.json"),
    });
  }, []);

  useEffect(() => {
    lottie.loadAnimation({
      container: container2.current,
      render: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../next.json"),
    });
  }, []);
  useEffect(() => {
    lottie.loadAnimation({
      container: container3.current,
      render: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../iban.json"),
    });
  }, []);
  useEffect(() => {
    lottie.loadAnimation({
      container: container7.current,
      render: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../next.json"),
    });
  }, []);
  useEffect(() => {
    lottie.loadAnimation({
      container: container4.current,
      render: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../next.json"),
    });
  }, []);
  useEffect(() => {
    lottie.loadAnimation({
      container: container5.current,
      render: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../coopte.json"),
    });
  }, []);
  useEffect(() => {
    lottie.loadAnimation({
      container: container6.current,
      render: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../meilleure.json"),
    });
  }, []);

  return (
    <div>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <div className="font-body font-bold text-2xl pl-1">Tutoriel</div>
        </AccordionSummary>

        <AccordionDetails>
          <div className="grid grid-cols-7 gap-2 place-content-center">
            <div>
              <h1 className="text-center text-green-light md:text-lg  xs:text-xs font-body">
                Étape 1
              </h1>
              <p className="text-center font-bold md:text-lg xs:text-xs font-body">
                Coopteur
              </p>
              <div
                className="container mx-auto md:h-40 xs:h-20"
                ref={container}
              ></div>
              <p className="text-justify md:text-base xs:text-xs font-body">
                Juste quelques secondes pour remplir vos informations en tant
                que coopteur.
              </p>
            </div>

            <div className="flex justify-center items-center">
              <div className="container2 md:h-32 xs:h-16" ref={container2} />
            </div>

            <div>
              <h1 className="text-center md:text-lg  text-green-light xs:text-xs font-body">
                Étape 2
              </h1>

              <p className="text-center font-bold md:text-lg xs:text-xs font-body">
                RIB
              </p>
              <div
                className="container3 md:h-40 xs:h-20"
                ref={container3}
              ></div>
              <p className="text-justify md:text-base xs:text-xs font-body">
                À présent nous fournir votre IBAN pour que I'on puisse vous
                rémunérer. Vérifiez plusieurs fois si nécessaire.
              </p>
            </div>

            <div className="flex justify-center items-center">
              <div className="container2 md:h-32 xs:h-16" ref={container4} />
            </div>

            <div>
              <h1 className="text-center md:text-lg  text-green-light xs:text-xs font-body">
                Étape 3
              </h1>

              <p className="text-center font-bold md:text-lg xs:text-xs font-body">
                Coopté
              </p>
              <div
                className="container5 md:h-40 xs:h-20"
                ref={container5}
              ></div>
              <p className="text-justify md:text-base xs:text-xs font-body">
                Ici vous inscrivez les informations de la personne à laquelle
                vous pensez pour le poste.
              </p>
            </div>

            <div className="flex justify-center items-center">
              <div className="container7 md:h-32 xs:h-16" ref={container7} />
            </div>

            <div>
              <h1 className="text-center md:text-lg  text-green-light xs:text-xs font-body">
                Étape 4
              </h1>

              <p className="text-center font-bold md:text-lg xs:text-xs font-body">
                La meilleure étape
              </p>

              <div
                className="container6 md:h-40 xs:h-20"
                ref={container6}
              ></div>
              <p className="text-justify md:text-base xs:text-xs font-body">
                LA meilleure étape! Grace à votre RIB on vous fait le virement
                correspondant à la somme indiquée sur l’annonce (après a
                validation de la période d'essai du coopté).
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
