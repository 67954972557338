import logo from "../../../src/logo.png";


export default function SidenavHeader() {
  return (
    <div className="bg-gray-900 flex items-center justify-center mb-6 pb-6 pt-3 sticky top-0 z-10">
      <img width={80} height={90} src={logo} alt="logo" />
    </div>
  );
}
