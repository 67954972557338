import HomeIcon from './icons/home';
import CreditsIcon from './icons/credits';
import ArchivesIcon from './icons/archives';

const data = [
  {
    title: 'Admin',
    icon: <HomeIcon />,
    link: '/admin',
  },
 
  {
    title: 'Cooptation',
    icon: <ArchivesIcon />,
    link: '/admin/coopteur',
  },
  {
    title: 'Candidats',
    icon: <CreditsIcon />,
    link: '/admin/candidats',
  },
  
  
];

export default data;
